// @flow

import React from 'react';
import {InlineBlock} from 'jsxstyle';

import {BLUE} from '@styles/colors';

import type {Element} from 'react';

type Props = {
  width?: string,
  border?: string,
  margin?: string,
  placeholder?: string,
  handleChange: (e: Event) => void,
  name?: string,
  value?: string,
  required?: boolean,
  type?: string,
  id?: string,
};

const mediaQueries = {
  sm: 'screen and (max-width: 768px)',
  lg: 'screen and (min-width: 1280px)',
};

export default ({
  placeholder,
  width,
  border,
  margin,
  handleChange,
  name,
  value,
  id,
  required,
  type,
}: Props): Element<*> => (
  <InlineBlock
    component="input"
    props={{
      onChange: e => handleChange(e),
      placeholder,
      name,
      value,
      required,
      id,
      type: type ? type : 'text',
    }}
    width={width ? width : 'auto'}
    padding="0 20px"
    fontSize="1.3rem"
    color={BLUE}
    backgroundColor="white"
    height="80px"
    border={border ? border : 'none'}
    borderRadius="0px"
    WebkitAppearance="none"
    WebkitBorderRadius="0px"
    MozAppearance="none"
    margin={margin}
    mediaQueries={mediaQueries}
    smWidth="calc(100% - 42px)"
  />
);
