import React, {Component, Fragment} from 'react';
import {Row, Col} from 'jsxstyle';
import Helmet from 'react-helmet';
import base64 from 'base-64';
import {graphql} from 'gatsby';
import {navigate} from '@reach/router';
import queryString from 'query-string';

import Layout from '@layouts';
import Link from '@components/Link';
import Section from '@components/layout/Section';
import SectionTitle from '@components/SectionTitle';
import {BLUE, ORANGE, WHITE} from '@styles/colors';
import Form from '@components/Form';
import type {State} from '@components/Form';
import {
  addressToGoogleFriendlyAddress,
  stripTagsFromWagtail,
  propertyHasUnits,
  getAddressFromAddressSet,
} from '@util';

import {SCHEMA_MAIN} from '@util/googleSchema';

type Props = {};

const {
  API_URL = process.env.GATSBY_FORM_SUBMISSION_URL,
  API_USERNAME = process.env.GATSBY_API_USERNAME,
  API_PASSWORD = process.env.GATSBY_API_PASSWORD,
} = process.env;

const mediaQueries = {
  sm: 'screen and (max-width: 768px)',
  lg: 'screen and (min-width: 1280px)',
};

const DEFAULT_ADDRESS = '780 Third Avenue New York, NY 10017';
const DEFAULT_PHONE_NUMBER = '212.427.9700';

function getPropertyOptions(properties) {
  const excludedProperties = ['The Victory', '345E94', '298 Mulberry'];
  const available = properties
                    .filter(property => propertyHasUnits(property))
                    .filter(property => !excludedProperties.includes(property.name));
  const options = available.map(({name, id}) => {
    return {
      id,
      value: id,
      text: name,
    };
  });
  
  return options.sort((a, b) => b.text.localeCompare(a.text));
}

function getContactInfoToRender(properties, subject) {
  const defaultPropertyData = {
    addressToRender: DEFAULT_ADDRESS,
    phoneNumberToRender: DEFAULT_PHONE_NUMBER,
  };
  if (!properties) return defaultPropertyData;
  const propertyNames = properties.map(property => property.name);
  if (propertyNames.includes(subject.trim())) {
    const [selectedProperty] = properties.filter(
      property => property.name === subject,
    );
    const {address, phoneNumber} = selectedProperty;
    const [{addressSet}] = address.neighborhood;
    const selectedPropertyAddress = getAddressFromAddressSet(addressSet);
    const selectedPropertyPhoneNumber = phoneNumber;
    return {
      addressToRender: selectedPropertyAddress,
      phoneNumberToRender: selectedPropertyPhoneNumber,
    };
  } else {
    return defaultPropertyData;
  }
}

function getCalloutCopyToRender(
  properties,
  landingHeadline,
  landingCopy,
  subject,
) {
  const defaultCopyToRender = {
    title: landingHeadline,
    description: stripTagsFromWagtail(landingCopy),
  };
  /** subject check is pretty bad here. we have a default value
   * that can be selected. One alternative would be to disable the
   * selection of the default value. Another solution could be to
   * create a first option that's selected by default with the text
   * as in the placeholder and a value smth like `defaultOption`.
   */
  if (!subject || !properties || subject === 'I am interested in...')
    return defaultCopyToRender;
  const propertyNames = properties.map(property => property.name);
  if (propertyNames.includes(subject.trim())) {
    return defaultCopyToRender;
  } else {
    return {
      title: 'How can we help?',
      description: "We're ready to chat about how we can help you.",
    };
  }
}

export default class Contact extends Component<Props, State> {
  searchParams = queryString.parse(this.props.location.search)

  propertyIds = {
    7 : "The Epic"
   }

  getPropertyKeyValue = (propertyKey, propertyName) => {
    for (const [key, value] of Object.entries(this.propertyIds)) {
      if(propertyName == value || propertyKey == key){
        return {
          "key": key, 
          "value": value
        };
      }
    }
    return {
      "key": "I want to inquire about a property", 
      "value": "I want to inquire about a property"
    };
  }
  
  state = {
    subject: this.searchParams.subject ? this.searchParams.subject : '',
    property: this.searchParams.property ? this.getPropertyKeyValue(null, this.searchParams.property).key : '',
    name: '',
    last_name: '-',
    building: '',
    apartment: '',
    email: '',
    comments: '',
    disabled: false
    
  };

  handleChange = (event: Event) => {
    const {target} = event;
    // $FlowFixMe
    const {name, value} = target;
    this.setState({
      [name]: value,
    });
  };

  handleSubmit = async (event: Event) => {
    event.preventDefault();

    this.setState({disabled: true});

    const formData = new FormData();

    for (const field of Object.keys(this.state)) {
      if (typeof this.state[field] === 'string') {
        formData.append(field, this.state[field]);
      }
      console.log(field,  this.state[field]);
    }

    if (window.location.search.includes('gitest') ){
      this.setState({ name: document.getElementById('name').value});
      this.setState({ email: document.getElementById('email').value});

      if( this.state['email'] && this.state['name']){
        const navigateTo =
          this.state.subject.toLocaleLowerCase() === 'property'
            ? `/confirmation/`
            : `/confirmation/?subject=general-inquiry`;
        navigate(navigateTo);
      } else {
        console.log('error')
        this.setState({disabled: false});
        return;
      }
    } 
    

    const postOptions = {
      method: 'POST',
      body: formData,
      credentials: 'include',
      mode: 'no-cors',
      headers: {
        Authorization:
          API_PASSWORD && API_USERNAME
            ? `Basic ${base64.encode(`${API_USERNAME}:${API_PASSWORD}`)}`
            : '',
      },
    };

    try {
      if (!API_URL) return;
      const navigateTo =
        this.state.subject.toLocaleLowerCase() === 'property'
          ? `/confirmation/`
          : `/confirmation/?subject=general-inquiry`;
      await fetch(API_URL, postOptions);
      navigate(navigateTo);
    } catch (error) {
      Promise.reject(error);
    }
  };

  render() {
    const {data} = this.props;
    const {page, properties} = data.wagtail;
    const {
      seoTitle,
      searchDescription,
      landingEyebrow,
      landingHeadline,
      landingCopy,
    } = page;
    const propertyOptions = getPropertyOptions(properties);

    const {addressToRender, phoneNumberToRender} = getContactInfoToRender(
      properties,
      this.state.property ? this.getPropertyKeyValue(this.state.property, null).value : '',
    );

    const {title, description} = getCalloutCopyToRender(
      properties,
      landingHeadline,
      landingCopy,
      this.state.subject,
    );

    return (
      <Fragment>
        <Helmet>
          <html lang="en" />
          <title>{seoTitle}</title>
          <meta name="description" content={searchDescription} />
          <script type="application/ld+json">
            {JSON.stringify(SCHEMA_MAIN)}
          </script>
        </Helmet>
        <Layout menuItemColor={BLUE} menuBackground={WHITE}>
          <Section height="auto" padding="140px 0">
            <Row
              alignItems="flex-start"
              justifyContent="center"
              height="auto"
              padding="20px 0"
              mediaQueries={mediaQueries}
              smHeight="auto"
              smFlexDirection="column"
              smPadding="0"
              smAlignItems="center"
            >
              <Col
                width="40%"
                alignItems="flex-start"
                justifyContent="center"
                mediaQueries={mediaQueries}
                smWidth="90%"
                smAlignItems="center"
                smPaddingBottom="40px"
              >
                <SectionTitle
                  eyebrow={landingEyebrow}
                  headline={title}
                  copy={description}
                  dividerColor={ORANGE}
                  alignItems="flex-start"
                  textAlign="left"
                  width="80%"
                  margin="0"
                >
                  <Col component="p" color={BLUE} lineHeight="24px" smMargin="0 0 20px 0" margin="0 0 40px 0" mediaQueries={mediaQueries}>
                    Address:
                    <Link
                      href={`https://www.google.com/maps/search/?api=1&query=${addressToGoogleFriendlyAddress(
                        addressToRender,
                      )}`}
                      style={{
                        color: BLUE,
                        textDecoration: 'underline',
                      }}
                      activeStyle
                    >
                      {addressToRender}
                    </Link>
                    <Link
                      href={`tel: ${phoneNumberToRender}`}
                      style={{
                        fontSize: '1rem',
                        fontWeight: 'bold',
                        textTransform: 'capitalize',
                        textDecoration: 'underline',
                        color: BLUE,
                        marginRight: '50px',
                      }}
                    >
                      {`Call: ${phoneNumberToRender}`}
                    </Link>
                  </Col>
                </SectionTitle>
              </Col>
              <Form
                handleChange={this.handleChange}
                handleSubmit={this.handleSubmit}
                state={this.state}
                propertyOptions={propertyOptions}
                disabled={this.state.disabled}
              />
            </Row>
          </Section>
        </Layout>
      </Fragment>
    );
  }
}

export const query = graphql`
  fragment ContactFragment on Wagtail_ContactPage {
    seoTitle
    searchDescription
    landingEyebrow
    landingHeadline
    landingCopy
  }
  query {
    wagtail {
      page(slug: "contact") {
        ...ContactFragment
      }
      properties {
        id
        name
        soldout
        phoneNumber
        floorplans {
          units {
            available
          }
        }
        address {
          neighborhood {
            name
            addressSet {
              street
              city
              state
              postalCode
            }
          }
        }
      }
    }
  }
`;