// @flow

import React, {Fragment} from 'react';
import {Col} from 'jsxstyle';

import {BLUE, WHITE, ORANGE} from '@styles/colors';
import Dropdown from './Dropdown';
import Input from './Input';
import TextArea from './TextArea';
import dropdownArrow from '@data/svg/DropdownArrow.svg';
import Button from '../Button';

import type {Element} from 'react';

type Props = {
  handleChange: (e: Event) => void,
  handleSubmit: (e: Event) => void,
  state: State,
  propertyOptions: [],
  disabled: boolean,
};

export type State = {
  subject: string,
  property: string,
  building: string,
  apartment: string,
  name: string,
  last_name: String,
  email: string,
  comments: string,

};

const mediaQueries = {
  sm: 'screen and (max-width: 768px)',
  lg: 'screen and (min-width: 1280px)',
};

const formOptions = [
  {
    id: 1,
    value: 'Property',
    text: 'Property',
  },
  {
    id: 2,
    value: 'Billing',
    text: 'Billing',
  },
  {
    id: 3,
    value: 'HR',
    text: 'HR',
  },
  {
    id: 4,
    value: 'General Inquiry',
    text: 'General Inquiry',
  },
];

export default ({
  handleChange,
  handleSubmit,
  state,
  propertyOptions,
  disabled,
}: Props): Element<*> => {
  return (
    <Col
      component="form"
      mediaQueries={mediaQueries}
      width="400px"
      smWidth="81%"
      props={{
        name: 'propertyInquiry',
        onSubmit: handleSubmit,
      }}
    >
      <Col
        component="fieldset"
        props={{
          form: 'propertyInquiry',
        }}
        width="100%"
      >
        <Dropdown
          name="subject"
          id="subject"
          options={[...formOptions]}
          width="100%"
          border={`1px solid ${BLUE}`}
          margin="0 0 40px 0"
          backgroundColor={WHITE}
          arrow={dropdownArrow}
          defaultValue="I am interested in..."
          handleChange={e => handleChange(e)}
          onInput={console.log('changed')}
          selectedValue={state.subject}
          mediaQueries={mediaQueries}
          smWidth="100%"
          smMargin="0 0 40px 0"
        />
        {/*{state.subject.toLocaleLowerCase() === 'property' && (
          <Dropdown
            name="property"
            id="property"
            options={[...propertyOptions]}
            width="100%"
            border={`1px solid ${BLUE}`}
            margin="0 0 40px 0"
            backgroundColor={WHITE}
            arrow={dropdownArrow}
            defaultValue="I want to inquire about a property"
            handleChange={e => handleChange(e)}
            selectedValue={state.property}
            mediaQueries={mediaQueries}
            smWidth="100%"
            smMargin="0 0 40px 0"
          />
        )}*/}
        {state.subject.toLocaleLowerCase() === 'billing' && (
          <Fragment>
            <Input
              name="building"
              placeholder="Building"
              value={state.building}
              width="calc(100% - 40px)"
              border={`1px solid ${BLUE}`}
              margin="0 0 40px 0"
              handleChange={e => handleChange(e)}
            />
            <Input
              name="apartment"
              placeholder="Apartment Number"
              value={state.apartment}
              width="calc(100% - 40px)"
              border={`1px solid ${BLUE}`}
              margin="0 0 40px 0"
              handleChange={e => handleChange(e)}
            />
          </Fragment>
        )}
        <Input
            name="name"
            placeholder={state.property == 10 ? "First Name" : "Name"}
            id="name"
            value={state.name}
            width="calc(100% - 40px)"
            border={`1px solid ${BLUE}`}
            margin="0 0 40px 0"
            handleChange={e => handleChange(e)}
          />
        {state.property == 10 && (
          <Input
            name="last_name"
            placeholder="Last Name"
            id="last_name"
            value={state.last_name}
            width="calc(100% - 40px)"
            border={`1px solid ${BLUE}`}
            margin="0 0 40px 0"
            handleChange={e => handleChange(e)}
          />
        )}
        <Input
          name="email"
          placeholder="Email"
          id="email"
          type="email"
          required
          value={state.email}
          width="calc(100% - 40px)"
          border={`1px solid ${BLUE}`}
          margin="0 0 40px 0"
          handleChange={e => handleChange(e)}
        />
        <TextArea
          name="comments"
          placeholder="Questions/Comments"
          value={state.comments}
          width="calc(100% - 40px)"
          border={`1px solid ${BLUE}`}
          margin="0 0 40px 0"
          handleChange={e => handleChange(e)}
        />
        <Button buttonText="Submit" disabled={disabled} />
      </Col>
    </Col>
  );
};
